import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi I'm Tonky</h1>
    <p>I want to help pups from all over Southern Califoria.</p>
    <p>Donations will help local pet rescue organizations.</p>
    <StaticImage
      src="../images/welovetonky.jpg"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="We Love Tonky"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/goldee/">Goldee</Link> <br />
      <Link to="/zac/">Zac</Link> <br />
      <Link to="/zoe/">Zoe</Link> <br />
      <Link to="/cruise/">Cruise</Link> <br />
      <Link to="/lucy/">Lucy</Link><br />
      <Link to="/hurleygigiitsy/">Hurley Gigi Itsy</Link>
    </p>
  </Layout>
)

export default IndexPage
